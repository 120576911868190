<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<b-row class="mt-4">
			<b-col cols="12">
				<h2>
					{{ translate('fill_autoship_info') }}
				</h2>
			</b-col>
			<b-col
				class="pt-2 pb-3"
				cols="12"
				v-html="translate('fill_autoship_info_description')" />
		</b-row>
		<div
			:class="{
				'mt-3': !['xs'].includes(windowWidth),
				'mt-2': ['xs'].includes(windowWidth),
			}"
			class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<autoship-products-choose
								:errors="errors"
								:show-dates-configurations="false"
								:allow-choose-periodicity="false"
								@updateProducts="updateFormField('products', $event)"
								@updatePeriodicity="updateFormField('autoship_periodicity', $event)"
								@updateDate="updateFormField('next_autoship_date', $event)" />
							<new-default-address
								:errors-import="errors"
								@onUpdate="updateFormField('address_id', $event)" />
							<new-default-credit-card
								:errors="errors"
								:last-shipping-id="form.address_id"
								@onUpdate="updateFormField('card_id', $event)" />
							<b-row
								v-if="autoshipDate"
								class="my-4">
								<b-col
									class="text-right"
									cols="12">
									<p
										class="text-info"
										v-html="translate('autoship_order_info', { date: autoshipDate })" />
								</b-col>
							</b-row>
						</flow-info-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FlowInfoGroup from '@/components/FlowInfo/Group';
import windowSizes from '@/mixins/WindowSizes';
import NewDefaultAddress from '@/views/BecomeAffiliate/components/NewDefaultAddress';
import AutoshipProductsChoose from '@/views/BecomeAffiliate/components/AutoshipProductsChoose';
import NewDefaultCreditCard from '@/views/BecomeAffiliate/components/NewDefaultCreditCard';
import AutoshipInformation from '@/util/AutoshipInformation';
import { AutoshipInformation as AutoshipInformationTranslations, Onboarding } from '@/translations';
import { customerRoles } from '@/settings/Roles';

export default {
	name: 'AutoshipInfoStep',
	messages: [AutoshipInformationTranslations, Onboarding],
	components: {
		NewDefaultCreditCard,
		AutoshipProductsChoose,
		NewDefaultAddress,
		FlowInfoGroup,
	},
	mixins: [windowSizes],
	props: {
		stepInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			saveAutoship: new AutoshipInformation(),
			customerRoles,
			form: {
				next_autoship_date: null,
				autoship_periodicity: null,
				card_id: null,
				address_id: null,
				products: { },
			},
		};
	},
	computed: {
		errors() {
			try {
				return this.saveAutoship.data.errors.errors;
			} catch (e) {
				return [];
			}
		},
		loading() {
			return !!this.saveAutoship.data.loading;
		},
		autoshipDate() {
			if (this.stepInfo.next_purchase_date) {
				return this.stepInfo.next_purchase_date;
			}
			return this.form.next_autoship_date;
		},
	},
	methods: {
		updateFormField(field, value) {
			let newValue = value;
			if (typeof value === 'undefined') {
				newValue = null;
			}
			this.form[field] = newValue;
			this.$emit('update-form', this.form);
		},
	},
};
</script>
